@import 'studio';

@mixin player-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $is-dark: map-get($theme, is-dark);

  $icon-size: 32px;

  .spt-player-control-small-button {
    padding: 0 4px !important;
    min-width: $icon-size + 8px !important; // + padding
    height: $icon-size * 1.2 !important;

    .mat-icon {
      //font-size: $icon-size;
      width: $icon-size;
      height: $icon-size;

      svg .icon-stroke {
        stroke: rgb(90, 92, 95);
      }

      svg .icon-fill {
        fill: #ffffff;
      }
    }
  }

  @if $is-dark == false {
    .spt-player-control-small-button {
    }
  }
}
