@use '@angular/material' as mat;
@import 'studio';

@mixin styles-help-content($theme) {
  $accent: map-get($theme, accent);
  $is-dark: map-get($theme, is-dark);

  .help-content-subtitle {
    margin-bottom: 0;
  }

  .help-content-params {
    font-style: italic;
  }

  .getting-started-title {
    @extend .mat-h1;
    text-align: center;
    color: mat.get-color-from-palette($accent, 500);
    font-weight: 500;
    margin-bottom: 30px;
  }

  .steps-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 30px;

    .step-number {
      border-radius: 50%;
      background-color: mat.get-color-from-palette($accent, 500);
      width: 40px;
      height: 40px;
      text-align: center;
      vertical-align: center;
      font-size: 30px;
      font-weight: 500;
      line-height: 1.35;
    }

    .step-task {
      margin-left: 20px;
    }
  }
}
