@use '@angular/material' as mat;

@mixin styles-spt-a-component($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $is-dark: map-get($theme, is-dark);

  .spt-a-component {
    color: mat.get-color-from-palette($primary, default);
  }

  @if $is-dark {
  }
}
