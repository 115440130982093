@use '@angular/material' as mat;
@import 'studio';

@mixin styles-header-help($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $is-dark: map-get($theme, is-dark);

  $header-item-selected: mat.get-color-from-palette($accent, default);

  @if $is-dark {
    .header-help {
      svg rect {
        fill: map-get($primary, default-contrast) !important;
      }

      &.header-item-selected-override {
        color: $header-item-selected;

        .mat-icon {
          color: $header-item-selected !important;
          fill: $header-item-selected !important;

          svg rect {
            fill: $header-item-selected !important;
          }
        }
      }
    }
  } @else {
    .header-help {
    }
  }
}
