@use '@angular/material' as mat;
@import 'studio';

@mixin styles-invite-musician-to-project($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $is-dark: map-get($theme, is-dark);

  $studio-palette: mat.$blue-grey-palette;
  $ix-777: mat.get-color-from-palette($studio-palette, 400);

  @if $is-dark {
    .invite-musician-to-project {
      .mat-expansion-panel {
        //background: mat.get-color-from-palette($ix-deep-mat-blue, 700);
      }

      .table-progress-bar {
        border-top: 1px solid white;
        //background: mat.get-color-from-palette($ix-deep-mat-blue, 800);
      }

      tr.musician-element-row:not(.example-expanded-row):hover {
        //background: $ix-777;
      }

      tr.musician-element-row:not(.example-expanded-row):active {
        //background: mat.get-color-from-palette($studio-palette, 300);
      }
    }
  } @else {
    .invite-musician-to-project {
    }
  }
}
