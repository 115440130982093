@import 'studio';

@mixin styles-spt-horizontal-volume-slider-component($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $is-dark: map-get($theme, is-dark);

  @if $is-dark {
    .spt-horizontal-volume-slider-cmp {
      .mat-button {
        background-color: transparent;
      }
    }
  } @else {
  }
}
