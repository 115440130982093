@use '@angular/material' as mat;
@import 'studio';

@mixin styles-left-sidenav($theme) {
  .mat-drawer.spt-left-drawer {
    .mat-drawer-inner-container {
      display: flex;
      flex-direction: column;
      overflow: hidden;

      .mixer-left-nav-container {
        display: flex;
        flex: 1 1 auto;
        overflow: hidden;

        li {
          .mat-icon {
            // Center icons horizontally in left nav
            margin-left: 7px;
          }
        }
      }
    }
  }

  .spt-sidenav-expand-trigger {
    border-top: solid 1px rgba(255, 255, 255, 0.12);
    //background-color: mat.get-color-from-palette($studio-palette, 600) !important;

    list-style-type: none;
    padding-left: 0;
    margin: 0;

    li {
      @include transition-all-swift-ease-in-out();
      //@include border-1(true, false, false, false);
      display: flex;
      flex-direction: row;
      //justify-content: flex-end;
      align-items: center;
      cursor: pointer;
      padding: 20px 20px 20px 20px;
      text-decoration: none;
      text-align: left;

      .mat-icon {
        @include transition-all-swift-ease-in-out();
        transform: rotate(0deg);
        transform-origin: center center;

        &.--rotate-icon {
          @include transition-all-swift-ease-in-out();
          transform: rotate(180deg);
          transform-origin: center center;
        }
      }

      &.--left-li {
        justify-content: flex-end;
      }

      &.--right-li {
        padding: 20px;
      }
    }
  }
}
