@mixin create-icon-size($size) {
  width: $size !important;
  height: $size !important;
  font-size: $size !important;

  svg {
    //top: -5px;
    position: relative;
    top: -1px;
  }
}

@mixin icon-size() {
  $icon-size-16: 16px;
  $icon-size-18: 18px;

  .g-spt-icon-16 {
    &.mat-icon {
      @include create-icon-size($icon-size-16);
    }
    & .mat-icon {
      @include create-icon-size($icon-size-16);
    }
  }

  .g-spt-icon-18 {
    &.mat-icon {
      @include create-icon-size($icon-size-18);
    }
    & .mat-icon {
      @include create-icon-size($icon-size-18);
    }
  }
}
