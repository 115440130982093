@use '@angular/material' as mat;

@mixin settings-home-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $is-dark: map-get($theme, is-dark);

  .settings-item {
    background: mat.get-color-from-palette($background, card);
    border-radius: 4px;
    padding: 10px;
  }

  @if $is-dark == false {
  }
}
