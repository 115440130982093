@use '@angular/material' as mat;
@import 'studio';

@mixin tree-select-list-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $is-dark: map-get($theme, is-dark);

  .tree-select-list {
    .tree-search {
      background-color: mat.get-color-from-palette($background, card);

      .mat-progress-bar {
        position: absolute;
        top: 0;
      }

      .search-input-wrapper {
        form {
          .mat-form-field {
            .mat-form-field-prefix {
              .mat-icon {
                padding-right: 6px;
                color: map-get($primary, 100);
              }
            }

            .mat-form-field-suffix {
              .mat-icon {
                color: map-get($primary, 100);
              }
            }
          }
        }
      }
    }

    .tree-select-list-container {
      background-color: mat.get-color-from-palette($background, card);

      .mat-list-item-content {
        padding-right: 18px;
      }
    }
  }

  @if $is-dark == false {
    .tree-select-list {
    }
  }
}
