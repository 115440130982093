@mixin chat-mix-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $is-dark: map-get($theme, is-dark);

  .chat-mix {
    mat-expansion-panel-header,
    .mat-expansion-panel-body {
      padding: 0;
    }

    mat-expansion-panel-header {
      .mat-content {
        margin-right: 10px;
      }
    }
  }

  @if $is-dark == false {
    .chat-mix {
    }
  }
}
