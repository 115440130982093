@use '@angular/material' as mat;
@import 'studio';

@mixin styles-right-sidenav($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $is-dark: map-get($theme, is-dark);

  .mixer-right-drawer-actions {
    background-color: mat.get-color-from-palette($background, card);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;

    //@include border-1(false, false, false, true);
    border-left: solid 1px mat.get-color-from-palette($foreground, divider);

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    width: 64px;
    z-index: 100;

    //RIGHT DRAWER

    //.mat-icon {
    //  @include transition-all-swift-ease-in-out();
    //  transform: rotate(0deg);
    //  transform-origin: center center;
    //
    //  &.rotate-icon {
    //    @include transition-all-swift-ease-in-out();
    //    transform: rotate(180deg);
    //    transform-origin: center center;
    //  }
    //}

    .mixer-right-drawer-items {
      .mat-stroked-button {
        width: 64px;
        padding: $right-sidenav-link-padding;

        border-radius: 0;
        border-top: 0;
        border-right: 0;
        border-left: 0;

        .mat-icon {
          $badge: 12px;
          .mat-badge-content {
            width: $badge;
            height: $badge;
            line-height: 12px;
            font-size: 8px;
            right: -6px;
            top: -5px;
          }
        }
      }
    }

    .mixer-right-drawer-trigger-wrapper {
      max-height: 64px;

      .mat-stroked-button {
        border-width: 0;
        border-radius: 0;
      }
    }
  }

  .mixer-right-drawer {
    & .mat-drawer {
      overflow-y: hidden;
    }

    .mat-drawer-inner-container {
      overflow-y: hidden;
    }
  }

  .select-options-title {
    color: mat.get-color-from-palette($accent);
  }

  table.select-option-table {
    width: 100%;

    td.mat-cell:last-of-type {
      padding-right: 0;
    }

    tr {
      cursor: pointer;
    }

    tr.project-detail-row {
      height: 0;
      td {
        //padding-bottom: 10px;
      }
    }

    tr.project-element-row:not(.project-expanded-row):hover {
      background: rgba(black, 0.12);
    }

    tr.project-element-row:not(.project-expanded-row):active {
      background: rgba(black, 0.12);
    }

    .project-element-row td {
      border-bottom-width: 0;
    }

    .project-element-detail {
      overflow: hidden;
      display: flex;
      width: 100%;
    }

    .project-element-diagram {
      width: 100%;
      min-width: 80px;
      //border: 2px solid black;
      //padding: 8px;
      //font-weight: lighter;
      //margin: 8px 0;
      min-height: 100%;
      margin-left: 65px;
    }

    .project-element-symbol {
      font-weight: bold;
      font-size: 40px;
      line-height: normal;
    }

    .project-element-description {
      padding: 16px;
    }

    .project-element-description-attribution {
      opacity: 0.5;
    }
  }

  table.no-border-bottom {
    border-bottom-width: 0;

    tr:last-child {
      border-bottom-width: 0;

      td {
        //border-bottom-width: 0;
      }
    }
  }

  #sample-rate-mismatch .mat-button-wrapper {
    display: flex;
    position: relative;
    width: 100%;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }
}
