@mixin styles-page-mixer-forms($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $is-dark: map-get($theme, is-dark);

  // Also in global styles
  .forms-router-outlet-container {
    position: absolute;
    top: 0;
    right: 64px;
    left: 0;
    z-index: 12;

    .ps-content {
      height: 100%;
    }
  }
}
