@import 'studio';

@mixin overlay-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $is-dark: map-get($theme, is-dark);

  .gt-overlay-panel {
    display: block;
    background-color: $studio-black;
    padding: 10px 20px 20px 20px;
  }

  .g-overlay-title-bar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 36px;
    width: 100%;
    background-color: $studio-black;

    & .mat-h3 {
      margin: 0;
    }
  }

  @if $is-dark == false {
    .overlay {
    }
  }
}
