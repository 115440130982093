@use '@angular/material' as mat;
@import 'studio';

@mixin slide-down-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $is-dark: map-get($theme, is-dark);

  // ANIMATIONS
  .slide-down-transition-wrapper {
    transform: translateY(-100%);
    height: 100%;
    background: $modals-scrim-black-dark;

    .slide-down-transition-background {
      background: mat.get-color-from-palette($background, background);
      padding: 0 20px 20px;
    }
  }

  .slide-down-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding: 20px 0;

    .slide-down-header-close {
      margin-right: -20px;
    }
  }

  .slide-down-content {
    //margin-top: 100px;
  }

  .slide-down-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    margin-top: 20px;
  }

  @if ($is-dark == false) {
  }
}
