@use '@angular/material' as mat;
@import 'studio';

@mixin spt-sidenav-list-component-theming($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $is-dark: map-get($theme, is-dark);

  .spt-mixer-sidenav {
    // LEFT SIDENAV

    .sidenav-list-container {
      font-size: $sidenav-text-font-size;
      font-weight: $sidenav-text-font-weight;
      min-width: 64px;

      .sidenav-item-selected {
        //font-weight: 600;
      }

      .sidenav-item-selected {
        color: mat.get-color-from-palette($accent, default);
        font-weight: 600;

        .mat-icon {
          color: mat.get-color-from-palette($accent, default);

          svg rect {
            fill: mat.get-color-from-palette($accent, default) !important;
          }
        }

        .nav-item-content {
          color: mat.get-color-from-palette($accent, default);

          svg rect {
            fill: mat.get-color-from-palette($accent, default) !important;
          }
        }
      }

      .mat-button-base {
        max-height: 61px;
      }

      .mat-icon {
        svg {
          rect {
            fill: map-get($primary, default-contrast) !important;
          }
        }
      }

      .is-font-icon {
        .mat-icon {
          font-size: $sidenav-font-icon-size;
          width: $sidenav-font-icon-size;
          height: $sidenav-font-icon-size;
        }
      }
    }

    ul {
      list-style-type: none;
      padding-left: 0;
      margin: 0;

      li {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        //width: 100%;

        cursor: pointer;
        text-decoration: none;
        text-align: left;
        //@include border-1(false, false, true, false);

        padding: 10px 16px;
        margin-top: 0;
        margin-bottom: 0;

        &:focus {
          outline: none;
        }

        //padding-left: 3px;
        white-space: nowrap;

        span:not(.mat-form-field-ripple) {
          @include transition-all-swift-ease-in-out();
          text-align: left;
          padding-left: 16px;
          opacity: 1;
          overflow: hidden;

          &.--close {
            //@include transition-all-swift-ease-in-out();
            opacity: 0;
            width: 0;
            padding-left: 0;
          }
        }

        & .--close {
          //@include transition-all-swift-ease-in-out();
          opacity: 0;
          width: 0;
          padding-left: 0;
          overflow: hidden;
        }

        &.display-icons-only {
          @include transition-all-swift-ease-in-out();

          span {
            @include transition-all-swift-ease-in-out();
            width: 0;
            margin: 0;
            overflow: hidden;
          }
        }

        .sidenav-info-label {
          color: rgba(255, 255, 255, 0.5);
        }

        a {
          display: block; /* Make the links appear below each other instead of side-by-side */
          text-align: center; /* Center-align text */
          padding: 16px; /* Add some padding */
          text-decoration: none;
          margin-top: 0;
          margin-bottom: 0;
          //padding-top: 20px;
          //padding-bottom: 20px;

          &:focus {
            outline: none;
          }
        }

        .nav-item-content {
          display: flex;
          flex-direction: row;
          align-items: center;
          width: 100%;
          padding-left: 3px;

          // icon
          color: white;
          svg rect {
            fill: map-get($primary, default-contrast) !important;
          }

          span:not(.mat-form-field-ripple) {
            @include transition-all-swift-ease-in-out();
            text-align: left;
            width: 85px;
            padding-left: 16px;
            opacity: 1;
            overflow: hidden;

            &.--close {
              //@include transition-all-swift-ease-in-out();
              opacity: 0;
              width: 0;
              padding-left: 0;
              overflow: hidden;
            }
          }

          &.display-icons-only {
            @include transition-all-swift-ease-in-out();

            span {
              @include transition-all-swift-ease-in-out();
              width: 0;
              margin: 0;
              overflow: hidden;
            }
          }
        }
      }
    }
  }
}
