@use '@angular/material' as mat;
@import 'studio';

@mixin styles-warn-select-song($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $is-dark: map-get($theme, is-dark);

  @if $is-dark {
    .warn-select-track-component {
      color: mat.get-color-from-palette($warn, default);
    }
  } @else {
  }
}
