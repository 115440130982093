@import 'studio';

@mixin global-track($theme) {
  .g-track-controls {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    min-width: ($track-controls-width);
    width: $track-controls-width;

    //padding-left: 10px;

    .g-spt-track-title,
    .g-spt-track-info {
      //margin-top: 5px;
      display: flex;
      font-size: 14px;
      height: 40px;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      //padding-left: 15px;
      //padding-right: 15px;
      width: 100%;
      //flex: 2;

      .g-spt-track-info-text {
        display: flex;
        flex: 1;
        flex-direction: row;
        justify-content: flex-end;
        padding-right: 15px;
      }

      //white-space: nowrap;
      //overflow: hidden;
      //text-overflow: ellipsis;
    }

    .g-spt-track-info {
      font-size: 12px;
      color: $studio-white;
    }

    .g-spt-track-icons {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;

      //.g-svg-icon-adjust {
      //  margin-top: 10px;
      //}
    }

    // Works with style
    // libs/mixer-browser-desktop/feature-page-mixer/src/lib/pages/console/components/song/track-audio/_audio-track-audio.component.theme.scss:19
    .g-track-action-icon-button {
      width: 40px;
      height: 40px;
      overflow: hidden;
    }
  }
}
