@import 'studio';

@mixin styles-spt-border-header($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $is-dark: map-get($theme, is-dark);

  .spt-border-header {
    border-top: 1px solid $default-border-color;
  }

  @if $is-dark {
  } @else {
  }
}
