@use '@angular/material' as mat;
@import 'studio';

@mixin latency-test-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $is-dark: map-get($theme, is-dark);

  .spt-latency-test {
    .latency-test-result {
      mat-card-content {
        color: mat.get-color-from-palette($warn, 500);
      }
    }
  }

  @if $is-dark == false {
    .spt-latency-test {
    }
  }
}
