@use '@angular/material' as mat;

@mixin console-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $is-dark: map-get($theme, is-dark);

  $action-color: mat.get-color-from-palette($primary, 700);
  $disabled-color: mat.get-color-from-palette($foreground, disabled);

  .g-console-mic-settings-left {
    width: 140px;
  }

  @if $is-dark == false {
  }
}
