@use '@angular/material' as mat;
@import 'studio';

@mixin styles-spt-border-container($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $is-dark: map-get($theme, is-dark);

  .spt-border-container {
    border: 1px solid $default-border-color;
    background: mat.get-color-from-palette($background, card);
  }

  @if $is-dark {
  } @else {
  }
}
