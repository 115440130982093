@import 'studio';

@mixin grid-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $is-dark: map-get($theme, is-dark);

  .spt-grid {
    .mat-grid-tile {
      border: $spt-border;

      .container {
        width: 100%;
        height: 100%;

        &.label {
          background: rgba(0, 0, 0, 0.22);
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
          align-items: center;
          white-space: nowrap;
          padding: 20px;
        }

        &.value {
          display: flex;
          flex-direction: row;
          align-items: center;
          white-space: nowrap;
          padding: 20px;
        }
      }
    }
  }

  @if $is-dark == false {
    .spt-grid {
    }
  }
}
