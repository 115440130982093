@import 'studio';

@mixin chat-overlay-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $is-dark: map-get($theme, is-dark);

  // Added to panelClass property in overlay config
  // libs/mixer-browser-desktop/theme/src/libcomponents/chat-overlay/chat-overlay.component.ts:185
  // libs/mixer-browser-desktop/theme/src/libcomponents/chat-overlay/chat-overlay.component.ts:213
  .gt-chat-overlay {
    display: block;
    background-color: $studio-black;
    padding: 10px 20px 20px 20px;
  }

  @if $is-dark == false {
    .gt-chat-overlay {
    }
  }
}
