@use '../../../../../../../node_modules/@angular/material/index' as mat;
@import 'studio';

@mixin about-dialog-component-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $is-dark: map-get($theme, is-dark);

  .gt-about-dialog-component {
    .mat-dialog-container {
      overflow: hidden;
    }

    .gt-header {
      .icon-color {
        fill: mat.get-color-from-palette(mat.$blue-grey-palette, 800);
      }

      .gt-foreground {
        .mat-icon {
          width: 396px;
          height: 138px;
        }
      }
    }
  }

  @if $is-dark == false {
    .gt-about-dialog-component {
    }
  }
}
