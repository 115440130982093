@use '@angular/material' as mat;

@mixin subscription-end-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $is-dark: map-get($theme, is-dark);

  spt-subscription-end {
    color: mat.get-color-from-palette($accent, 500);
    border: 1px solid mat.get-color-from-palette($accent, 500);
  }

  @if $is-dark == false {
    spt-subscription-end {
    }
  }
}
