@use '@angular/material' as mat;
@import 'studio';

@mixin styles-help-tooltip($theme) {
  $is-dark: map-get($theme, is-dark);

  @if $is-dark {
    .help-tooltip-overlay {
      background: $default-background-color;
      border: 1px solid $spt-light-opacity;
    }
  }
}
