// https://color.adobe.com/create/color-wheel
// Foreground
$studio-divider: #313c4e;

// Background original
//$studio-black: #212936;
//$studio-black-light: #2b3648;
//$studio-light: #56657f;

$studio-black: #191f29;
$studio-black-light: #232c3b;
$studio-light: #4e5b73;

// #303030
$background: $studio-black-light;
// #424242
$card: $studio-black;
// #212121
$app-bar: $studio-black;
// #616161
$unselected-chip: $studio-light;

// Dark White Extra
$studio-white-extra: #afbdd1;
$studio-white-extra-lighter: #c2d1e7;
// Dark White
$studio-white: #8997b1;

$modals-scrim-black-dark: rgba($studio-black, 0.54);

$spt-light-opacity: rgba(255, 255, 255, 0.12);
$spt-dark-opacity: rgba(0, 0, 0, 0.12);

$default-border-color: rgba(255, 255, 255, 0.1);
$default-background-color: rgb(19, 21, 24);
$spt-dark-label-color: rgba(255, 255, 255, 0.7);
$studio-faded-title: rgba(255, 255, 255, 0.6);

// TODO Refactor to his

$spt-border: 1px solid $spt-light-opacity;
$spt-border-dashed: 2px dashed rgba(255, 255, 255, 0.12);
$border-1-color: #3c3c3c;
