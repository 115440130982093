@mixin perfect-scrollbar($theme) {
  $railSize: 12px;
  $thumbSize: $railSize - 4px;

  $thumbBorderColor: #000;
  $railBorderColor: #fff;
  //$thumbBackgroundColor: #999;

  $railColorBackgroundColor: rgba(0, 0, 0, 0.4);
  $opacity-rail: 0;
  $opacity-rail-hover: 1;

  $thumbBackgroundColor: rgba(255, 255, 255, 0.12);
  $opacity-thumb: 0.9;
  $opacity-thumb-hover: 0.9;

  /*
   * Container style
   */
  .ps {
    overflow: hidden !important;
    overflow-anchor: none;
    -ms-overflow-style: none;
    touch-action: auto;
    -ms-touch-action: auto;
  }

  /*
   * Scrollbar rail styles
   */
  .ps__rail-x {
    cursor: pointer;
    display: none;
    transition: background-color 0.2s linear, opacity 0.2s linear;
    -webkit-transition: background-color 0.2s linear, opacity 0.2s linear;
    height: $railSize;
    /* there must be 'bottom' or 'top' for ps__rail-x */
    bottom: 0;
    /* please don't change 'position' */
    position: absolute;

    //border-top: 1px solid $railBorderColor;
    //border-bottom: 1px solid $railBorderColor;
    background-color: $railColorBackgroundColor;
    //opacity: 0;
    opacity: $opacity-rail; // original
  }

  .ps__rail-y {
    cursor: pointer;
    display: none;

    transition: background-color 0.2s linear, opacity 0.2s linear;
    -webkit-transition: background-color 0.2s linear, opacity 0.2s linear;
    //width: 15px; // original
    width: $railSize;
    /* there must be 'right' or 'left' for ps__rail-y */
    right: 0;
    /* please don't change 'position' */
    position: absolute;

    //border-left: 1px solid $railBorderColor;
    //border-right: 1px solid $railBorderColor;
    background-color: $railColorBackgroundColor;
    opacity: $opacity-rail; // original
  }

  .ps--active-x > .ps__rail-x,
  .ps--active-y > .ps__rail-y {
    display: block;
    //background-color: transparent; // original
  }

  .ps:hover > .ps__rail-x,
  .ps:hover > .ps__rail-y,
  .ps--focus > .ps__rail-x,
  .ps--focus > .ps__rail-y,
  .ps--scrolling-x > .ps__rail-x,
  .ps--scrolling-y > .ps__rail-y {
    //opacity: 0.6; // Original

    background-color: $railColorBackgroundColor !important;
    opacity: $opacity-rail-hover !important;
  }

  .ps .ps__rail-x:hover,
  .ps .ps__rail-y:hover,
  .ps .ps__rail-x:focus,
  .ps .ps__rail-y:focus,
  .ps .ps__rail-x.ps--clicking,
  .ps .ps__rail-y.ps--clicking {
    background-color: $railColorBackgroundColor;
    opacity: $opacity-rail-hover;
  }

  /*
   * Scrollbar thumb styles
   */
  .ps__thumb-x {
    cursor: pointer;
    //background-color: #aaa;// original
    background-color: $thumbBackgroundColor !important;
    transition: background-color 0.2s linear, height 0.2s ease-in-out;
    -webkit-transition: background-color 0.2s linear, height 0.2s ease-in-out;
    height: $thumbSize;
    /* there must be 'bottom' for ps__thumb-x */
    bottom: 2px;
    /* please don't change 'position' */
    position: absolute;
  }

  .ps__thumb-y {
    cursor: pointer;
    //background-color: #aaa;// original
    background-color: $thumbBackgroundColor !important;
    border-radius: 6px;
    transition: background-color 0.2s linear, width 0.2s ease-in-out;
    -webkit-transition: background-color 0.2s linear, width 0.2s ease-in-out;
    //width: 6px; // original
    width: $thumbSize;
    /* there must be 'right' for ps__thumb-y */
    right: 2px; // original
    //right: -1;
    /* please don't change 'position' */
    position: absolute;
    //border: 1px solid $thumbBorderColor;
  }

  .ps__rail-x:hover > .ps__thumb-x,
  .ps__rail-x:focus > .ps__thumb-x,
  .ps__rail-x.ps--clicking .ps__thumb-x {
    background-color: $thumbBackgroundColor;
    opacity: $opacity-thumb-hover;
    //height: 11px;
    height: $thumbSize;
  }

  .ps__rail-y:hover > .ps__thumb-y,
  .ps__rail-y:focus > .ps__thumb-y,
  .ps__rail-y.ps--clicking .ps__thumb-y {
    background-color: $thumbBackgroundColor;
    opacity: $opacity-thumb-hover;
    //width: 11px; // original
    width: $thumbSize;
  }

  /* MS supports */
  @supports (-ms-overflow-style: none) {
    .ps {
      overflow: auto !important;
    }
  }

  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .ps {
      overflow: auto !important;
    }
  }
}
