@use '@angular/material' as mat;

@mixin action-icon-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $is-dark: map-get($theme, is-dark);

  $action-color: mat.get-color-from-palette($primary, 700);
  $icon-disabled-color: mat.get-color-from-palette($foreground, disabled);
  //$icon-off-color: mat.get-color-from-palette(mat.$gray-palette, 600);
  $icon-off-color: #265780;
  $icon-on-color: mat.get-color-from-palette($primary, default);
  $icon-active-color: mat.get-color-from-palette($accent, default);

  .spt-primary-action-button {
    background-color: $action-color;
  }

  .spt-primary-action-icon {
    color: $action-color;
  }

  .spt-icon-disabled {
    color: $icon-disabled-color;

    .mat-badge-content {
      background-color: $icon-disabled-color !important;
    }
  }

  .spt-icon-off {
    color: $icon-off-color;

    .mat-badge-content {
      background-color: $icon-off-color !important;
    }
  }

  .spt-icon-on {
    color: $icon-on-color;

    .mat-badge-content {
      background-color: $icon-on-color !important;
    }
  }

  .spt-icon-active {
    color: $icon-active-color;

    .mat-badge-content {
      background-color: $icon-active-color !important;
    }
  }

  .spt-action-text-color {
    color: $action-color;
  }

  @if $is-dark == false {
    .action-icon {
    }
  }
}
