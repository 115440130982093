@use '@angular/material' as mat;
@import 'studio';

@mixin styles-spt-border-container-label($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $is-dark: map-get($theme, is-dark);

  @if $is-dark {
    .spt-border-container-label {
      color: $spt-dark-label-color;
    }

    .spt-border-container-label-border {
      //border: 1px solid $default-border-color;
      //border-top: 1px solid mat.get-color-from-palette($foreground, divider);
      //border-left: 1px solid mat.get-color-from-palette($foreground, divider);
      //border-right: 1px solid mat.get-color-from-palette($foreground, divider);
      background: mat.get-color-from-palette($background, card);
    }
  } @else {
  }
}
