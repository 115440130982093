@import 'studio';

@mixin offset-overlay-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $is-dark: map-get($theme, is-dark);

  .offset-overlay-panel {
    background-color: $studio-black;

    .mat-form-field-infix {
      width: 50px;
    }

    .mat-form-field-wrapper {
      padding-bottom: 0;
    }

    .mat-form-field-underline {
      bottom: 0;
    }
  }

  @if $is-dark == false {
    .gt-offset-overlay {
    }
  }
}
