@import 'studio';

@mixin list-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $is-dark: map-get($theme, is-dark);

  .spt-list-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    .spt-list-title {
      font-weight: 500;
    }

    .spt-list-value {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      text-align: right;

      .mat-icon {
        margin-left: 10px;
        top: 20px;
      }
    }
  }

  @if $is-dark == false {
    .spt-list-item {
    }
  }
}
