@use '@angular/material' as mat;

@mixin styles-message-cmp($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $is-dark: map-get($theme, is-dark);

  @if $is-dark {
    $speech-bg: rgb(18, 23, 33);
    $border-color: rgba(255, 255, 255, 0.2);
    $pointer: 10px;

    .message-cmp {
      .speech-bubble-ds {
        background: $speech-bg;
        border: 1px solid $border-color;

        .mat-drawer-container,
        .mat-drawer-inner-container {
          background-color: $speech-bg;

          .mat-button-focus-overlay {
            opacity: 0 !important;
          }

          .message-drawer {
            background-color: mat.get-color-from-palette(mat.$red-palette, 500);
          }
        }
      }

      .speech-bubble-ds-arrow::before {
        border-left: 23px - $pointer solid transparent;
        border-top: 23px - $pointer solid $border-color;
      }

      .speech-bubble-ds-arrow::after {
        border-left: 21px - $pointer solid transparent;
        border-top: 21px - $pointer solid $speech-bg;
      }

      .opened.speech-bubble-ds-arrow::after {
        border-top: 11px solid mat.get-color-from-palette(mat.$red-palette, 500) !important;
      }

      &.author {
        .stagename {
          //color: mat.get-color-from-palette($primary, 600);
        }
      }

      &.by {
        .speech-bubble-ds-arrow {
          border-right: 21px - $pointer solid transparent;
        }

        .speech-bubble-ds-arrow::before {
          border-right: 23px - $pointer solid transparent;
        }

        .speech-bubble-ds-arrow::after {
          border-right: 21px - $pointer solid transparent;
        }

        .stagename {
          //color: mat.get-color-from-palette(mat.$deep-orange-palette, 600);
        }
      }

      .date {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        color: rgba(255, 255, 255, 0.4);
      }
    }
  } @else {
    .message-cmp {
    }
  }
}
