@import 'studio';

@mixin upload-from-filesystem-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $is-dark: map-get($theme, is-dark);

  .upload-from-filesystem {
    border: $spt-border-dashed;

    form {
      width: 100%;

      .mat-input-element {
        cursor: pointer;
      }

      .mat-icon {
        cursor: pointer;
      }
    }
  }

  @if $is-dark == false {
    .upload-from-filesystem {
    }
  }
}
