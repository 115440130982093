@use '@angular/material' as mat;
@import 'studio';

@mixin chat-cmp-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $is-dark: map-get($theme, is-dark);

  .chat-cmp {
    .warn-add-conversations {
      color: mat.get-color-from-palette($warn, 500);
    }

    spt-select-track {
      .mat-form-field {
        width: 100%;
      }
    }

    spt-conversation {
      position: relative;
      width: 100%;
    }
  }

  @if $is-dark == false {
    .chat-cmp {
    }
  }
}
