@use '@angular/material' as mat;
@import 'studio';

// Needs to be in global styles for D3 Color Picker
@mixin styles-color-picker($theme) {
  //@include styles-color-picker-classes();

  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $is-dark: map-get($theme, is-dark);

  @if $is-dark {
    .color-picker-overlay {
      //background: $modals-scrim-black-dark;
      background: $default-background-color;
      border: 1px solid $border-dark;

      .color-title-bar {
        //background: rgba(255, 255, 255, 0.06);
        background: $default-background-color;
        border-bottom: 1px solid $spt-light-opacity;
      }

      .selected {
        border: 2px solid #ffffff;
      }

      .selected-color {
        box-shadow: inset 0 0 0 2px #ffffff;
      }

      .palettes::-webkit-scrollbar {
        //background-color: mat.get-color-from-palette($ix-deep-blue, 500);
        background-color: rgba(255, 255, 255, 0.2);
        width: 8px;
      }

      .palettes::-webkit-scrollbar-thumb {
        border-radius: 4px;
        //background-color: mat.get-color-from-palette(mat.$light-blue-palette, 900);
        //background-color: rgba(255, 255, 255, 0.4);
        background: $default-background-color;
        border: 1px solid $modals-scrim-black-dark;
        -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
      }
    }
  } @else {
    .color-picker-overlay {
    }
  }
}
