/* You can add global styles to this file, and also import other style files */
// Custom Theming for Angular Material
@use '@angular/material' as mat;
@use './app/app.theme' as app;
@use 'libs/web-global/theme/src/lib/theming/theme-studio/constants.scss' as con;
@use 'libs/web-global/ui/src/lib/theming.lib' as web-global-ui;
@import 'studio';

$typography-config: mat.define-typography-config();

@include mat.core($spt-typography);

@include mat.all-component-themes($studio-theme);

//$candy-app-primary: mat.define-palette(mat.$blue-gray-palette);
//$candy-app-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);
//$candy-app-warn: mat.define-palette(mat.$orange-palette);
//$candy-app-theme: mat.define-dark-theme($candy-app-primary, $candy-app-accent, $candy-app-warn);

//@include mat.core-theme($candy-app-theme);
//@include mat.button-theme($candy-app-theme);
//@include mat.checkbox-theme($candy-app-theme);

// keep after color picker so color picker
// overlay has styles from theme overrides

@include web-global-ui.styles();

@import 'libs/web-global/theme/src/lib/styles/studio-ui-styles';
@include studio-ui-styles($studio-theme, con.$max-width, con.$max-width-wide);
@include web-global-ui.color($studio-theme);

@import 'libs/mixer-browser-desktop/ui/src/lib/theming.lib';
@import 'libs/mixer-browser-desktop/ui/src/lib/components/color-picker/color-picker.component.theme';
@import 'libs/mixer-browser-desktop/feature-app/src/lib/theme.lib';
@import 'libs/mixer-browser-desktop/feature-page-settings/src/lib/theming.lib';
@import 'libs/mixer-browser-desktop/feature-page-mixer/src/lib/theming.lib';
@import 'libs/mixer-browser-desktop/ui/src/lib/sidenav/sidenav-list/spt-sidenav-list.component.theming';

@include ui-theme($studio-theme);
@include styles-color-picker($studio-theme);
@include feature-app-theme($studio-theme);
@include settings-theming($studio-theme);
@include feature-page-mixer-theming($studio-theme);
@include spt-sidenav-list-component-theming($studio-theme);

//@include spt-material-theme-overrides($studio-theme);

/* You can add global styles to this file, and also import other style files */
$background: map-get($studio-theme, background);

html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
  overflow: hidden;
  color: $studio-white-extra-lighter;
  background: mat.get-color-from-palette($background, background);
}
