@import 'studio';

@mixin stepper-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $is-dark: map-get($theme, is-dark);

  //.spt-stepper-content {
  //
  //}

  .spt-stepper-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    margin-top: 30px;
    width: 100%;

    .spt-stepper-footer-left {
      button {
        margin-right: 20px;
      }
    }

    .spt-stepper-footer-right {
      button {
        margin-left: 20px;
      }
    }
  }

  @if $is-dark == false {
  }
}
