@use '@angular/material' as mat;
@import 'studio';

@mixin styles-move-settings-workspace-dialogue($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $is-dark: map-get($theme, is-dark);

  .settings-dialog-cmp {
    .mat-card .mat-card-title {
      color: mat.get-color-from-palette($warn, default);
    }
  }

  @if $is-dark {
  } @else {
  }
}
