@use '@angular/material' as mat;
@import 'studio';

@mixin styles-header-route-link-icon($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $is-dark: map-get($theme, is-dark);

  //$background-color: map-get($ix-deep-mat-blue, 700);
  //$background-color-mini-fab: mat.get-color-from-palette($accent, 800);
  $header-item-selected: mat.get-color-from-palette($accent, default);

  .header-button {
    min-width: 42px;

    @if $is-dark {
    }
    .mat-button-icon {
      color: map-get($primary, default-contrast);
      //background-color: $background-color;
    }

    svg rect {
      fill: map-get($primary, default-contrast) !important;
    }

    &.header-item-selected-override {
      color: $header-item-selected;

      .mat-icon {
        color: $header-item-selected !important;
        fill: $header-item-selected !important;

        svg rect {
          fill: $header-item-selected !important;
        }
      }
    }
  }
}
