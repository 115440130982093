@import 'studio';

@mixin audio-track-component-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $is-dark: map-get($theme, is-dark);

  spt-audio-track {
    .g-spt-track-icons,
    .g-spt-track-title,
    .g-spt-track-info {
      $icon-size: 18px;

      // works with style
      // libs/mixer-browser-desktop/feature-page-mixer/src/lib/pages/console/components/song/track-audio/track-audio.component.scss:39
      & .mat-icon {
        width: $icon-size !important;
        height: $icon-size !important;
        font-size: $icon-size !important;
        line-height: $icon-size !important;

        svg {
          //top: -5px;
          position: relative;
        }
      }
    }
  }

  @if $is-dark == false {
    spt-audio-track {
    }
  }
}
