@import 'studio';

@mixin spt-microphone-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $is-dark: map-get($theme, is-dark);

  .spt-microphone {
    .mat-form-field {
      width: 100%;

      .mat-form-field-wrapper {
        width: 100%;
      }
    }
  }

  @if $is-dark == false {
    .spt-microphone {
    }
  }
}
